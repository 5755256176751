import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import FeedbackPage from '@/components/FeedbackPage.vue';
import AboutPage from '@/components/AboutPage.vue';

const routes = [
  { path: '/', component: HomePage, name: 'Home' },
  { path: '/feedback', component: FeedbackPage, name: 'Feedback' },
  { path: '/about', component: AboutPage, name: 'About' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;