<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>Opioid Conversion Tool</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- Menu button for mobile (visible only on smaller screens) -->
      <v-btn icon @click="drawer = !drawer" class="menu-button d-md-none">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <!-- Desktop navigation (hidden on mobile) -->
      <v-btn text to="/" class="nav-btn d-none d-md-flex">Home</v-btn>
      <v-btn text to="/feedback" class="nav-btn d-none d-md-flex">Feedback</v-btn>
      <v-btn text to="/about" class="nav-btn d-none d-md-flex">About</v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list>
        <v-list-item-group>
          <v-list-item @click="$router.push('/')">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/feedback')">
            <v-list-item-title>Feedback</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/about')">
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-container class="pt-16">
      <router-view></router-view>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style scoped>
/* Only include component-specific styles here, if any */
</style>