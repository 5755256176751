<template>
  <v-card-title class="page-title">
    <slot></slot>
    <span v-if="appVersion" class="version">v{{ appVersion }}</span>
  </v-card-title>
</template>

<script>
import pkg from '../../package.json'; // Import the whole package.json as 'pkg'

export default {
  name: 'PageTitle',
  data() {
    return {
      appVersion: pkg.version, // Access the version property
    };
  },
};
</script>

<style scoped>
/* You don't need to redefine .page-title here since it's in global-styles.css */
.version {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: #666;
}
</style>