<template>
  <v-container class="pa-5">
    <v-card class="mx-auto pa-5" max-width="600">
      <!-- Use the PageTitle component for consistent title styling -->
      <PageTitle>Feedback</PageTitle>
      
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <!-- Name Field -->
          <v-text-field
            v-model="name"
            label="Your Name"
            :rules="[v => !!v || 'Name is required']"
            required
          ></v-text-field>

          <!-- Message Field -->
          <v-textarea
            v-model="message"
            label="Your Message"
            :rules="[v => !!v || 'Message is required']"
            required
          ></v-textarea>

          <!-- Submit Button -->
          <v-btn class="mt-4" color="primary" @click="submitFeedback">Submit Feedback</v-btn>
        </v-form>
      </v-card-text>

      <!-- Success Alert -->
      <v-alert v-if="success" type="success" class="mt-4">
        Thank you for your feedback!
      </v-alert>
      <v-alert v-if="error" type="error" class="mt-4">
        There was an issue submitting your feedback. Please try again.
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

export default {
  name: "FeedbackPage",
  components: {
    PageTitle,
  },
  data() {
    return {
      name: '',
      message: '',
      success: false,
      error: false,
      valid: true,
    };
  },
  methods: {
    submitFeedback() {
      if (this.$refs.form.validate()) {
        // Send data to the PHP script
        fetch('/submit_feedback.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.name,
            message: this.message,
          }),
        })
          .then(response => response.json())
          .then(data => {
            if (data.status === 'success') {
              this.success = true;
              this.error = false;
              this.clearForm();
            } else {
              this.error = true;
              console.error(data.message);
            }
          })
          .catch(error => {
            this.error = true;
            console.error("Error submitting feedback:", error);
          });
      }
    },
    clearForm() {
      this.name = '';
      this.message = '';
      this.$refs.form.resetValidation();
    }
  },
};
</script>

<style scoped>
/* Optional additional styles */
</style>