// plugins/vuetify.js
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css'; // Import Material Design Icons

// Create and export Vuetify instance
export default createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        titleFontSize: '2rem',
        titleFontWeight: 'bold',
      },
    },
  },
});