<template>
  <v-container class="pa-5">
    <v-card class="mx-auto pa-5" max-width="600">
      <PageTitle>About This App</PageTitle>
      <v-card-text>
        <p>
          I'm a doctor working at University Hospitals of Leicester (UHL) in the field of medicine. I created this app based on UHL guidelines to assist doctors within the trust with quickly and efficiently verifying their opioid conversions.
        </p>
        <p>
          This tool is designed to support accuracy in our everyday clinical tasks, saving time while maintaining patient safety. I hope this app proves helpful for healthcare professionals and contributes to improving our workflows.
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

export default {
  name: "AboutPage",
  components: {
    PageTitle,
  },
};
</script>

<style scoped>
/* Additional styles if needed */
p {
  margin-bottom: 1.5em;
}
</style>