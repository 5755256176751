<template>
  <v-container class="pa-5">
    <v-card class="mx-auto pa-5" max-width="800">
      <!-- Use the PageTitle component for consistent title styling -->
      <PageTitle>Opioid Conversion Tool</PageTitle>
      
      <v-card-text>
        <!-- Added class "instruction-text" to control the spacing below the paragraph -->
        <p class="instruction-text text-center">
          Select your current opioid and route, then your target opioid and route, and enter the dose to get an equivalent dosage.
        </p>

        <!-- Group for Current Opioid and Current Route -->
        <v-row class="no-gutters">
          <v-col cols="12" md="6" class="p-0 m-0">
            <v-select
              class="form-field"
              v-model="currentOpioid"
              :items="opioids"
              label="Current Opioid"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="p-0 m-0">
            <v-select
              class="form-field"
              v-model="currentRoute"
              :items="capitalizedRouteOptions(currentOpioid)"
              label="Current Route"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <!-- Group for Target Opioid and Target Route -->
        <v-row class="no-gutters">
          <v-col cols="12" md="6" class="p-0 m-0">
            <v-select
              class="form-field"
              v-model="targetOpioid"
              :items="opioids"
              label="Target Opioid"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="p-0 m-0">
            <v-select
              class="form-field"
              v-model="targetRoute"
              :items="capitalizedRouteOptions(targetOpioid)"
              label="Target Route"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <!-- Enter Dose Field -->
        <v-text-field
          class="form-field"
          v-model="dose"
          :label="doseLabel"
          type="number"
          dense
        ></v-text-field>

        <!-- Convert Button -->
        <v-btn class="form-field" color="primary" @click="convertDose">Convert</v-btn>

        <!-- Result Alert -->
        <v-alert
          v-if="result !== null"
          type="success"
          class="form-field"
          border="left"
          elevation="2"
        >
          Equivalent Dose: {{ result }} {{ targetDoseUnit }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

const conversionTable = {
  Morphine: {
    Oral: { toMorphine: 1, fromMorphine: 1 },
    Subcut: { toMorphine: 2, fromMorphine: 2 },
  },
  Diamorphine: {
    Subcut: { toMorphine: 3, fromMorphine: 3 },
  },
  Oxycodone: {
    Oral: { toMorphine: 1.5, fromMorphine: 1.5 },
    Subcut: { toMorphine: 3, fromMorphine: 3 },
  },
  Alfentanil: {
    Subcut: { toMorphine: 30, fromMorphine: 30 },
  },
  Buprenorphine: {
    Patch: { toMorphine: 2.4, fromMorphine: 2.4 },
  },
  Fentanyl: {
    Patch: { toMorphine: 2.4, fromMorphine: 3.6 },
  },
};

export default {
  name: 'HomePage',
  components: {
    PageTitle,
  },
  data() {
    return {
      currentOpioid: '',
      currentRoute: '',
      targetOpioid: '',
      targetRoute: '',
      dose: '',
      result: null,
      opioids: ['Morphine', 'Diamorphine', 'Oxycodone', 'Alfentanil', 'Buprenorphine', 'Fentanyl'],
      appVersion: require('../../package.json').version, // Load version dynamically
    };
  },
  computed: {
    doseLabel() {
      return this.currentRoute === 'Patch' ? 'Enter Dose (µg per 24 hours)' : 'Enter Dose (mg per 24 hours)';
    },
    currentDoseUnit() {
      return this.currentRoute === 'Patch' ? 'µg/hr' : 'mg per 24 hours';
    },
    targetDoseUnit() {
      return this.targetRoute === 'Patch' ? 'µg/hr' : 'mg per 24 hours';
    },
  },
  watch: {
    currentOpioid() {
      this.resetCurrentRoute();
      this.resetResult();
    },
    targetOpioid() {
      this.resetTargetRoute();
      this.resetResult();
    },
    currentRoute() {
      this.resetResult();
    },
    targetRoute() {
      this.resetResult();
    },
    dose() {
      this.resetResult();
    },
  },
  methods: {
    convertDose() {
      const { currentOpioid, currentRoute, targetOpioid, targetRoute, dose } = this;

      const toMorphineFactor = conversionTable[currentOpioid]?.[currentRoute]?.toMorphine;
      if (!toMorphineFactor) {
        this.result = `Conversion to oral morphine not available for this opioid and route.`;
        return;
      }
      const morphineEquivalent = dose * toMorphineFactor;

      const fromMorphineFactor = conversionTable[targetOpioid]?.[targetRoute]?.fromMorphine;
      if (!fromMorphineFactor) {
        this.result = `Conversion from oral morphine not available for this target opioid and route.`;
        return;
      }
      const targetDose = morphineEquivalent / fromMorphineFactor;

      this.result = targetDose.toFixed(2);
    },
    resetResult() {
      this.result = null;
    },
    capitalizedRouteOptions(opioid) {
      const routes = {
        Morphine: ['Oral', 'Subcut'],
        Diamorphine: ['Subcut'],
        Oxycodone: ['Oral', 'Subcut'],
        Alfentanil: ['Subcut'],
        Buprenorphine: ['Patch'],
        Fentanyl: ['Patch'],
      };
      return routes[opioid] || [];
    },
    resetCurrentRoute() {
      const availableRoutes = this.capitalizedRouteOptions(this.currentOpioid);
      if (!availableRoutes.includes(this.currentRoute)) {
        this.currentRoute = '';
      }
    },
    resetTargetRoute() {
      const availableRoutes = this.capitalizedRouteOptions(this.targetOpioid);
      if (!availableRoutes.includes(this.targetRoute)) {
        this.targetRoute = '';
      }
    },
  },
};
</script>

<style scoped>
/* General spacing between form fields */
.form-field {
  margin-bottom: 4px; /* Reduced spacing */
}

/* Remove padding and margin from columns */
.v-col {
  padding: 0 !important;
  margin: 0 !important;
}

/* Add spacing below the instruction text */
.instruction-text {
  margin-bottom: 1rem; /* Adjust this as needed */
}
</style>